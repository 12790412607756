import React from 'react';

import * as styles from './ArticleContent.module.scss';

const ArticleContent = ({content}) => {
  return (
    <div
      className={styles.Content}
      dangerouslySetInnerHTML={{__html: content}}
    />
  );
};

export default ArticleContent;
