import React from 'react';
import TimeStamp from './TimeStamp';
import * as styles from './ContentItem.module.scss';

function ContentItem(props) {
  const {
    id,
    title,
    link,
    pubDate,
    parent,
    author,
    content,
    dayCount,
    hourCount,
    ttr,
    onClick,
  } = props;
  const handleItemClick = (evt) => {
    window.gtag &&
      window.gtag('event', 'click', {
        event_category: 'Article',
        event_action: 'Open',
        event_label: link,
      });
    window.gtag &&
      window.gtag('event', 'click', {
        event_category: 'Article',
        event_action: 'Twitter',
        event_label: JSON.stringify({
          url: link,
          title,
          twitter: parent.twitter,
        }),
      });

    evt.stopPropagation();
    onClick({title, content, link, author: author || parent.title, ttr});
  };

  return (
    <div
      key={id}
      className={styles.Item}
      id={id}
      data-hour-count={hourCount}
      data-day-count={dayCount}
    >
      <button className={styles.ItemTopContainer} onClick={handleItemClick}>
        <h2 className={styles.ItemText}>
          <span
            className={styles.Underline}
            // style={{transitionDuration: `${title.length * 0.01}s`}}
            dangerouslySetInnerHTML={{__html: title}}
          />
        </h2>
      </button>
      <div className={styles.ItemBottomContainer}>
        <div className={styles.ItemBottomText}>
          <a className={styles.PageLink} href={parent.path}>
            <div>{parent.title}</div>
          </a>
          {' - '}
          <TimeStamp date={pubDate} />
        </div>
      </div>
    </div>
  );
}

export default ContentItem;
