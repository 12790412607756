import React from 'react';
import {Helmet} from 'react-helmet';

const Head = ({title, description}) => {
  return (
    <Helmet>
      <meta charset="UTF-8" />
      <title>{`Readss - ${title}`}</title>
      <meta name="description" content={description} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, viewport-fit=cover"
      />
      <link rel="apple-touch-icon" sizes="180x180" href="icon-apple.png" />
      <link
        href="https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;800&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  );
};

export default Head;
