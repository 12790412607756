import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';

import ArticleContent from './ArticleContent';
import Close from '../assets/close.svg';
import Link from '../assets/link.svg';
import {trapFocus, removeTrapFocus} from '../utilities/focus';

import * as styles from './Sheet.module.scss';

function Sheet(props) {
  const {title, link, author, open, content, ttr, closeSheet, onTransitionEnd} =
    props;
  const [copied, setCopied] = useState(false);
  const sheetRef = useRef(null);
  const progressRef = useRef(null);
  const sheetContentRef = useRef(null);
  const [slidingDown, setSlidingDown] = useState(false);
  const [slideStart, setSlideStart] = useState(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        closeSheet();
      }
    };
    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  });

  useEffect(() => {
    if (progressRef && progressRef.current) {
      setTimeout(() => {
        progressRef.current.style.width = '0%';
      }, 200);
    }
  }, [open]);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  const handleScroll = () => {
    if (open) {
      const {scrollHeight, clientHeight, scrollTop} = sheetContentRef.current;
      let percent = scrollTop / (scrollHeight - clientHeight);
      percent = percent < 0 ? 0 : percent;
      percent = percent > 1 ? 1 : percent;
      progressRef.current.style.width = `${percent * 100}%`;
    }
  };

  const handleSheetTransition = () => {
    if (!open) {
      sheetContentRef.current.scrollTop = 0;
      removeTrapFocus(sheetRef.current);
      onTransitionEnd();
    } else {
      trapFocus(sheetRef.current);
      sheetRef.current.focus();
    }
  };

  const handleLinkClick = (evt) => {
    const link = evt.currentTarget.href;
    window.gtag &&
      window.gtag('event', 'click', {
        event_category: 'Article',
        event_action: 'Link',
        event_label: link,
      });
    return true;
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(link);
    setCopied(true);
  };

  const handlePointerDown = (evt) => {
    const handlePointerMove = (evt2) => {
      // const

      evt2.preventDefault();
      evt2.stopPropagation();
    };

    const handlePointerUp = (evt1) => {
      evt1.preventDefault();
      evt1.stopPropagation();
      document.removeEventListener('touchend', handlePointerUp);
      document.removeEventListener('touchmove', handlePointerMove);
      setSlidingDown(false);
      if (evt1.changedTouches[0].clientY - slideStart > 100) {
        closeSheet();
      }
    };

    evt.preventDefault();
    evt.stopPropagation();
    setSlidingDown(true);
    setSlideStart(evt.touches[0].clientY);
    document.addEventListener('touchend', handlePointerUp);
    document.addEventListener('touchmove', handlePointerMove);
  };

  return (
    <div
      className={classNames(styles.Sheet, open && styles.Open)}
      ref={sheetRef}
      tabIndex={-1}
      onTransitionEnd={handleSheetTransition}
    >
      <div className={styles.SheetActions}>
        <button
          className={styles.CloseButton}
          aria-label="Close details"
          onClick={closeSheet}
        >
          <Close className={styles.CloseIcon} />
        </button>
        <div className={styles.RightActions}>
          <button className={styles.ItemButton} onClick={handleCopy}>
            {copied ? 'Copied!' : 'Copy link'}
          </button>
          <a
            href={link}
            className={styles.ItemLink}
            target="_blank"
            rel="noreferrer"
            onClick={handleLinkClick}
          >
            <span>Read on site</span>
            <Link className={styles.ItemLinkIcon} />
          </a>
        </div>
      </div>
      <div className={styles.SheetHeader} onTouchStart={handlePointerDown}>
        <div className={styles.SheetHeaderContent}>
          <div
            className={styles.SheetTitle}
            dangerouslySetInnerHTML={{__html: title}}
          />
          <div className={styles.SheetSubtitle}>
            <span className={styles.SheetAuthor}>
              {author ? `by ${author}` : ''}
            </span>
            <span className={styles.SheetTtr}>
              {Math.ceil(ttr / 60)} min read
            </span>
          </div>
        </div>
      </div>
      <div className={styles.ScrollProgress} ref={progressRef}></div>
      <div
        className={styles.SheetContent}
        ref={sheetContentRef}
        onScroll={handleScroll}
      >
        <ArticleContent content={content} />
      </div>
    </div>
  );
}

export default Sheet;
