import React from 'react';
import {Link} from 'gatsby';
import * as styles from './Pagination.module.scss';

const Pagination = ({pagination}) => {
  if (!pagination || pagination.numPages === 1) {
    return null;
  }

  const {currentPage, numPages} = pagination;

  const prevPage = currentPage > 1 && (
    <Link to={currentPage === 2 ? '/' : `/page/${currentPage - 1}`}>
      ⬅️ Prev
    </Link>
  );

  const nextPage = currentPage < numPages && (
    <Link to={currentPage === numPages + 1 ? '/' : `/page/${currentPage + 1}`}>
      Next ➡️
    </Link>
  );

  return (
    <div className={styles.Pagination}>
      <div>{prevPage}</div>
      <div>Page {currentPage}</div>
      <div>{nextPage}</div>
    </div>
  );
};

export default Pagination;
