import React from 'react';

import Content from './Content';
import Head from './Head';
import Icon from '../assets/icon.svg';
import Readss from '../assets/readss.svg';

import * as styles from './Page.module.scss';

const Page = ({
  title,
  hideTitle,
  path,
  feedItems,
  description,
  breadcrumb,
  children = null,
  pagination,
}) => {
  return (
    <>
      <Head title={title} description={description} />
      <a className={styles.SkipToContent} href="#main">
        Skip to content
      </a>
      <div className={styles.HeaderContainer}>
        <div className={styles.DesktopHeader}>
          <a href="/" className={styles.HomeLink}>
            <Icon className={styles.Icon} />
            <Readss className={styles.TitleSvg} />
          </a>
        </div>
      </div>
      <main className={styles.ContentContainer} id="main">
        {feedItems ? (
          <Content
            title={hideTitle ? undefined : title}
            feeds={feedItems}
            path={path}
            breadcrumb={breadcrumb}
            pagination={pagination}
          />
        ) : (
          children
        )}
      </main>
    </>
  );
};

export default Page;
