import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';

import ContentItem from './ContentItem';
import PageTitle from './PageTitle';
import Sheet from './Sheet';

import * as styles from './Content.module.scss';
import Pagination from './Pagination';

function Content({feeds, title, breadcrumb, path, pagination}) {
  const [sheetOpen, setSheetOpen] = useState(false);
  const [sheetDetails, setSheetDetails] = useState({});

  const contentRef = useRef(null);
  const lastFocus = useRef(null);

  useEffect(() => {
    if (sheetOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
  }, [sheetOpen]);

  const handleItemClick = ({title, content, link, author, ttr}) => {
    if (sheetOpen) {
      setSheetOpen(false);
      return;
    }
    lastFocus.current = document.activeElement;
    setSheetOpen(true);
    setSheetDetails({
      title,
      content,
      link,
      author,
      ttr,
    });
  };

  const handleSheetClose = () => {
    setSheetOpen(false);
  };

  const handleSheetTransitionEnd = () => {
    setSheetDetails({});
    if (
      lastFocus &&
      lastFocus.current &&
      lastFocus.current.id !== 'gatsby-focus-wrapper'
    ) {
      lastFocus.current.focus();
    }
  };

  const closeSheet = (evt) => {
    if (sheetOpen) {
      evt.preventDefault();
    }
    handleSheetClose();
  };

  const itemMarkup = feeds.map((itemDetails) => {
    const key = `content-item-${itemDetails.id}`;
    return <ContentItem {...itemDetails} key={key} onClick={handleItemClick} />;
  });

  return (
    <>
      <div onClick={closeSheet} role="button">
        <div
          ref={contentRef}
          className={classNames(styles.Content, sheetOpen && styles.Disabled)}
          disabled={sheetOpen}
        >
          <div className={styles.TopContainer}>
            <PageTitle title={title} breadcrumb={breadcrumb} path={path} />
          </div>
          {itemMarkup.length > 0 ? itemMarkup : <div>No feeds</div>}
          <Pagination pagination={pagination} />
        </div>
      </div>
      <Sheet
        title={sheetDetails.title}
        link={sheetDetails.link}
        content={sheetDetails.content}
        ttr={sheetDetails.ttr}
        author={sheetDetails.author}
        open={sheetOpen}
        closeSheet={handleSheetClose}
        onTransitionEnd={handleSheetTransitionEnd}
      />
    </>
  );
}

export default Content;
